import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-scroll'

import { Title, Button, Section, Box, Text } from '../../components/Core'

import { device } from '../../utils'
// import imgL from '../../assets/image/png/portrait-1.png'
import imgL from '../../assets/image/jpg/portfolio-image-y.jpg'

const ImgRight = styled.img`
  height: 400px;
  width: 400px;
  max-height: 100%;
  max-width: 100%;
  /* fill the container, preserving aspect ratio, and cropping to fit */
  object-fit: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
`

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" className="mb-5 mb-lg-0">
              <Box>
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                  DATA-DRIVEN FULL-STACK ENGINEER, UX/UI DESIGNER, LIFELONG
                  LEARNER
                </Text>
                <Title variant="hero">
                  I help launch and grow digital products.
                </Title>

                <Box mt="52px">
                  <Link
                    to="works"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    <Button arrowRight>Explore works </Button>
                  </Link>
                </Box>
              </Box>
            </Col>
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={imgL} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Hero
